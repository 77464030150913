@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=PT+Sans+Narrow:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
  font-family: 'Open Sans';
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.glassmorphismBox {
  background: rgba(11, 11, 11, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 55%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.open-sans {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  /* Replace with actual weight */
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.pt-sans-narrow-regular {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.pt-sans-narrow-bold {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.reg-txt {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  margin-top: .5rem;
  font-size: 1.5rem;
  color: white;
}

.row {
  display: flex;
  justify-content: space-between;
}

a {
  text-decoration: none;
  font-size: 1.25rem;
  color: white;
  display: flex;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;
  text-align: center;
}

.logo {
  margin-top: 1rem;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 650;
  font-size: 7rem;
  color: #e7e7e5;
  text-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 8px 16px rgba(0, 0, 0, 0.1),
    0px 16px 32px rgba(0, 0, 0, 0.1);
}

.purple-five {
  color: rgb(132, 0, 255);
}

.background {
  background-image: linear-gradient(to right bottom, #0d3961, #043460, #002e5e, #00285c, #00225a, #0b2257, #132155, #192152, #22264f, #2a2c4c, #313149, #373746);
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  margin-top: 1.5rem;
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1000px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
  }
  .logo {
    font-size: 7rem;
  }
  .grid-container {
    grid-template-columns: 50% 50% 50% 50%;
  }
  .glassmorphismBox {
    width: 100%;
  }
  .background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.reg-txt2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  color: #e7e7e5;
  text-shadow:
        0px 1px 2px rgba(0, 0, 0, 0.1),
        0px 2px 4px rgba(0, 0, 0, 0.1),
        0px 4px 8px rgba(0, 0, 0, 0.1),
        0px 8px 16px rgba(0, 0, 0, 0.1),
        0px 16px 32px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: -2.5rem;
  width: 150%;
}

.btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -2rem;
  text-align: center;
  width: 100%;
  align-items: center;
}

.btn {
  width: 9rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(10, 10, 10, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border: .5px solid rgba(255, 255, 255, 0.2);
  color: rgb(240, 240, 240);
  text-decoration: none;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.btn a {
  color: rgb(240, 240, 240);
  text-decoration: none;
  text-align: center;
  align-items: center;
}

.btn:hover {
  background-color: rgb(140, 46, 199);
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.article-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  color: white;
}

.grid-container {
    display: inline-grid;
      grid-template-rows: 100% 100% 100% 100%;
        grid-template-columns: 25% 25% 25% 25%;
        justify-items: center;
        align-items: center;
    height: 75vh;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  background: rgba(11, 11, 11, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

.grid-item {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: rgba(7, 7, 7, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.article-title,
.tag,
.author,
.date,
.excerpt p {
  font-family: "Open Sans", sans-serif;
  color: white;
}

.article-title {
  font-size: 1rem;
  background-color: #000000;
  opacity: .7;
  width: 100%;
  height: 2rem;
  text-align: center;
  backdrop-filter: blur(10px);
}

.author {
  font-size: .75rem;
  background-color: #2765c9;
  border-radius: 15px;
  padding: .5rem;
  width: auto;
  height: 1rem;
  text-align: center;
  backdrop-filter: blur(10px);
  cursor: pointer;
  position: absolute;
  bottom: 15%;
  left: 2%;
}

.excerpt p {

  font-size: .75rem;
  background-color: #000000;
  opacity: .7;
  width: 100%;
  height: 2rem;
  position: absolute;
  bottom: 0%;
  left: 0%;
}

.image img {
  max-width: 100%
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #404040 #1c1c1c;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #1c1c1c;
}

*::-webkit-scrollbar-thumb {
  background-color: #404040;
  border-radius: 50px;
  border: 3px double #ffffff;
}